@import "src/assets/styles/variables.scss";

.header {
  height: 60px;
  width: 100%;
  position: absolute;

  & img {
    left: 5vw;
    height: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  & button {
    right: 5vw;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: none;
    @include secondary-button;
    @include media-breakpoint-up($sm) {
      display: block;
    }
  }

  @include media-breakpoint-up($lg) {
    height: 100px;
    & img {
      height: 40%;
    }
  }
}
