@import "src/assets/styles/variables.scss";

.footer {
  background: $bold;
  width: 100%;
  padding: 50px 0 10px 0;

  & .footer-columns {
    margin: 0 5%;

    @include media-breakpoint-up($md) {
      display: grid;
    }

    img {
      height: 32px;
    }

    & .footer-column {
      grid-row: 1;

      & h3 {
        color: $secondary;
        text-transform: uppercase;
        margin-bottom: 0;
      }

      & ul {
        color: rgba($secondary, 0.5);
        list-style-type: none;
        padding: 0 0 10px 0;
      }
    }

    @include media-breakpoint-up($md) {
      & h3 {
        margin: 0;
      }
      & .footer-column + .footer-column {
        padding-left: 60px;
      }
    }
  }

  & .copyright {
    width: 100%;
    text-align: center;
    color: rgba($secondary, 0.5);
    font-size: smaller;
  }
}
