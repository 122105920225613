@import "src/assets/styles/colors.scss";

$primary: $precision-teal;
$secondary: $glacial-serenity;
$tertiary: $glacial-horizon;
$bold: $midnight-pulse;

@mixin text-color-primary {
  color: $primary;
}

@mixin text-color-inverted {
  color: $secondary;
}

@mixin text-color-bold {
  color: $bold;
}

@mixin text-heading {
  @include text-color-bold;
}

@mixin text-h1 {
  @include text-heading;
  font-size: 50px;
  font-weight: 700;
  @include media-breakpoint-up($xl) {
    font-size: 68px;
  }
}

@mixin text-h2 {
  @include text-heading;
  font-size: 18px;
  font-weight: 400;
  @include media-breakpoint-up($xl) {
    font-size: 24px;
  }
}

@mixin button {
  border-radius: 36px;
  border-style: none;
  font-size: medium;
  font-weight: bold;
  text-align: center;
  padding: 15px 20px;
  margin: 5px;
}

@mixin primary-button {
  @include button;
  @include text-color-inverted;
  background-color: $primary;
}

@mixin secondary-button {
  @include button;
  @include text-color-primary;
  background-color: $tertiary;
}

// BreakPoints https://getbootstrap.com/docs/5.0/layout/breakpoints/
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

@mixin media-breakpoint-up($breakpoint) {
  @media (min-width: #{$breakpoint}) {
    @content;
  }
}
