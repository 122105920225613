@import "src/assets/styles/variables.scss";

.home {
  scroll-behavior: smooth;

  & .section {
    width: 100vw;

    @include media-breakpoint-up($xl) {
      display: grid;
    }

    & .section-text-content {
      padding: 5%;
      @include media-breakpoint-up($xl) {
        padding: 15% 20%;
        grid-column: 1;
        grid-row: 1;
      }

      & .section-h1 {
        @include text-h1;
        color: $tertiary;
      }

      & .section-h2 {
        @include text-h2;
        color: $tertiary;
        @include media-breakpoint-up($md) {
          margin-top: 50px;
        }
      }

      & .section-ctas {
        max-width: $md;
        margin: auto;
        display: flex;
        align-items: center;

        & .section-cta {
          @include secondary-button;
          display: inline-block;
          max-width: $md/3;
          min-width: $sm/3;
          width: 90%;
          padding: 15px 0;
          
          @include media-breakpoint-up($md) {
            margin: 10px;
            grid-row: 1;
          }
        }
      }
    }

    & .section-img {
      width: 800px;
      margin: auto;
      max-width: 98%;

      & img {
        max-width: 100%;
      }

      @include media-breakpoint-up($xl) {
        grid-column: 2;
        grid-row: 1;
      }
    }
  }

  .section.hero-section {
    min-height: 100vh;
    background: linear-gradient(to bottom right, rgba($primary, 0.85), #2f6266);
    & .section-text-content {
      padding-top: 100px;
      @include media-breakpoint-up($lg) {
        padding-top: 200px;
      }
    }
  }

  .section.ccm-section {
    background: $tertiary;

    & .section-text-content {
      @include media-breakpoint-up($xl) {
        grid-column: 2;
      }

      & .section-h1 {
        color: $primary;

        & .subheading {
          margin: 0;
          padding: 0;
          font-size: 20px;
          font-weight: 700;
          @include media-breakpoint-up($xl) {
            font-size: 24px;
          }
        }
      }
      & .section-h2 {
        color: $bold;
      }
      & .bold {
        color: $bold;
      }

      & .section-cta {
        @include primary-button;
      }
    }

    & .section-img {
      @include media-breakpoint-up($xl) {
        grid-column: 1;
      }
    }
  }

  .section.user-experience {
    background: $secondary;

    & .section-text-content {
      @include media-breakpoint-up($xl) {
        width: 25vw;
      }
      & .section-h1 {
        color: $bold;
      }
      & .section-h2 {
        color: $bold;
      }
      & .bold {
        color: $primary;
      }
    }
  }

  .section.levels {
    background: no-repeat url("../../assets/images/levels-background.png"),
      linear-gradient(to bottom right, rgba($primary, 0.85), #2f6266);
    overflow-x: scroll;

    & .section-text-content {
      padding: 0;
      margin: auto;
      min-width: $md;
      display: grid;

      & .level + .level {
        margin-left: 100px;
      }
      & .level {
        grid-row: 1;
        padding: 20px;
        max-width: 300px;
        & h3 {
          margin: 0 0 5% 0;
          color: $secondary;
        }
        & .level-img {
          width: 100%;
          & img {
            width: 100%;
            max-width: 320px;
          }
        }
      }
    }
  }
  .section.empowering-section {
    background: $tertiary;

    & .section-text-content {
      @include media-breakpoint-up($xl) {
        grid-column: 2;
      }

      & .section-h1 {
        color: $bold;
      }
      & .section-h2 {
        color: $bold;
      }

      & .section-cta {
        @include primary-button;
      }
    }

    & .section-img {
      @include media-breakpoint-up($xl) {
        margin-left: 80px;
        grid-column: 1;
      }
    }
  }

  .section.embrace-section {
    background: linear-gradient(to bottom right, rgba($primary, 0.85), #2f6266);
  }
}
