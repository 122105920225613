@font-face {
  font-family: "Roobert";
  font-weight: 700;
  src: local("Roobert-Bold"),
    url("../fonts/Roobert-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Roobert";
  font-weight: 700;
  font-style: italic;
  src: local("Roobert-BoldItalic"),
    url("../fonts/Roobert-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Roobert";
  font-weight: 900;
  src: local("Roobert-Heavy"),
    url("../fonts/Roobert-Heavy.ttf") format("truetype");
}
@font-face {
  font-family: "Roobert";
  font-weight: 900;
  font-style: italic;
  src: local("Roobert-HeavyItalic"),
    url("../fonts/Roobert-HeavyItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Roobert";
  font-weight: 300;
  src: local("Roobert-Light"),
    url("../fonts/Roobert-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Roobert";
  font-weight: 300;
  font-style: italic;
  src: local("Roobert-LightItalic"),
    url("../fonts/Roobert-LightItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Roobert";
  font-weight: 500;
  src: local("Roobert-Medium"),
    url("../fonts/Roobert-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Roobert";
  font-weight: 500;
  font-style: italic;
  src: local("Roobert-MediumItalic"),
    url("../fonts/Roobert-MediumItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Roobert";
  font-weight: 400;
  src: local("Roobert-Regular"),
    url("../fonts/Roobert-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Roobert";
  font-weight: 400;
  font-style: italic;
  src: local("Roobert-RegularItalic"),
    url("../fonts/Roobert-RegularItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Roobert";
  font-weight: 600;
  src: local("Roobert-SemiBold"),
    url("../fonts/Roobert-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Roobert";
  font-weight: 600;
  font-style: italic;
  src: local("Roobert-SemiBoldItalic"),
    url("../fonts/Roobert-SemiBoldItalic.ttf") format("truetype");
}
