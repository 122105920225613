@import "src/assets/styles/variables.scss";
@import "src/assets/styles/fonts.scss";

body {
  margin: 0;
}
* {
  font-family: "Roobert";
}
a,
button {
  text-decoration: none;
  cursor: pointer;
}
